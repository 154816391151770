<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: labTestActionBar.vue
Description: This file is the part of the labTest page. It contains various components which acts on the lab Test table.
-->
<template>
  <layout-toolbar class="header">
    <!-- <er-single-day-picker
      :value="selectedDate"
      :dateWithTime="true"
      class="align-item-left"
      value-format="yyyy-MM-dd[T]HH:mm:ss.SSS[Z]"
      format="dd-MMM-yy HH:mm"
      @change-prev-day="prevDayClick"
      @change-next-day="nextDayClick"
      @change-day="handlePresentDateChange"
    ></er-single-day-picker> -->
    <el-date-picker
      size="mini"
      :clearable="false"
      v-model="selectedDate"
      type="datetime"
      format="yyyy-MM-dd HH:mm"
      value-format="yyyy-MM-dd[T]HH:mm:ss.SSS[Z]"
      class="date-picker-style"
      popper-class="date-picker"
      :teleported="false"
      placement="top-left"
      :picker-options="fromDatePickerOptions"
      @change="handlePresentDateChange"
    />
    <er-column-selector
      key-to-store-defaults="lab-test-table"
      :title="`Column ${$t('Comn_visibility')}`"
      :columns="tableColumns"
      @column-save="handelColumnSave"
      @change-selection="handleChangeInColumnSelection"
    ></er-column-selector>
  </layout-toolbar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import moment from "moment";
export default {
  mixins: [datesHandlerMixin, userPreferenceMixin],
  props: {
    tableColumns: {
      default: () => []
    }
  },
  data: function () {
    return {
      searchString: "",
      selectedDate: "",
      fromDatePickerOptions: {
        disabledDate: this.disabledDate
      },
    };
  },
  created() {
    this.selectedDate = this.dhm__formatTZ(
      this.dhm__dateUtilsLib.getCurrDateInGivenTZ(this.getUserTimeZoneString),
      "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
    );
    this.changeDate(this.selectedDate);
  },
  computed: {
    ...mapGetters("user", {
      isReadOnly: "isReadOnly"
    }),
    dialogVisibleStatus: {
      get() {
        return this.getStateOfDialogBox;
      },
      set(value) {
        this.changeDialogBoxVisibleStatus(value);
      }
    }
  },
  methods: {
    ...mapActions("labTests", {
      changeDate: "changeDate"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    disabledDate(date) {
      return (
        moment(date).format("YYYY-MM-DD") + "T00:00:00.000Z" >
        moment(new Date()).format("YYYY-MM-DD") + "T23:59:59.999Z"
      );
    },
    async prevDayClick(date) {
      // await this.$emit("reload");
      this.selectedDate = date;
      this.mixPanelEventGenerator({ eventName: "Lab Tests Table - Date Filter" });
      this.changeDate(date);
    },
    async nextDayClick(date) {
      // await this.$emit("reload");
      this.selectedDate = date;
      this.mixPanelEventGenerator({ eventName: "Lab Tests Table - Date Filter" });
      this.changeDate(date);
    },
    async handlePresentDateChange(date) {
      console.log("date changed");
      // await this.$emit("reload");
      this.selectedDate = date;
      this.mixPanelEventGenerator({ eventName: "Lab Tests Table - Date Filter" });
      this.changeDate(date);
    },
    handelColumnSave() {
      this.mixPanelEventGenerator({ eventName: "Lab Tests Table - Column Selector - Save" });
    },
    handleChangeInColumnSelection(selectedColumns) {
      this.$emit("column-change", selectedColumns);
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: flex-end;

  .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 160px;
  }
}
</style>

<style lang="scss">
  .el-picker-panel {
    @include responsiveProperty(left, 1032px, 1032px, 1089px, true);
  }
</style>
