var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{staticClass:"lab-test-table"},[(!_vm.loading)?_c('el-row',[_c('er-data-tables',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],key:_vm.$i18n.locale,attrs:{"element-loading-background":"white","type":"white-header-table","table-data":_vm.tableDataArrComputed,"el-table-props":_vm.tableProps,"selectedColumnHeaders":_vm.selectedColumns,"selectedColumnHeadersSortable":true,"action-column":false,"layout":"table","uniqueId":"lab-test-table","columns":_vm.tableColumns},scopedSlots:_vm._u([{key:"pond_id_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(header.label))])]}},{key:"co3_header",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"table-col-header-container"},[_c('el-tooltip',{attrs:{"popperClass":"lab-test-table-el-tooltip__popper","content":header.sub_label,"placement":"top","effect":"light"}},[_c('p',[_vm._v(_vm._s(header.label))])])],1)]}},{key:"hco3_header",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"table-col-header-container"},[_c('el-tooltip',{attrs:{"popperClass":"lab-test-table-el-tooltip__popper","content":header.sub_label,"placement":"top","effect":"light"}},[_c('p',[_vm._v(_vm._s(header.label))])])],1)]}},{key:"do_header",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"table-col-header-container"},[_c('el-tooltip',{attrs:{"popperClass":"lab-test-table-el-tooltip__popper","content":header.sub_label,"placement":"top","effect":"light"}},[_c('p',[_vm._v(_vm._s(header.label))])])],1)]}},{key:"temperature_header",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"table-col-header-container"},[_c('el-tooltip',{attrs:{"popperClass":"lab-test-table-el-tooltip__popper","content":header.sub_label,"placement":"top","effect":"light"}},[_c('p',[_vm._v(_vm._s(header.label))])])],1)]}},{key:"no2_header",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"table-col-header-container"},[_c('el-tooltip',{attrs:{"popperClass":"lab-test-table-el-tooltip__popper","content":header.sub_label,"placement":"top","effect":"light"}},[_c('p',[_vm._v(_vm._s(header.label))])])],1)]}},{key:"ammonia_header",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"table-col-header-container"},[_c('el-tooltip',{attrs:{"popperClass":"lab-test-table-el-tooltip__popper","content":header.sub_label,"placement":"top","effect":"light"}},[_c('p',[_vm._v(_vm._s(header.label))])])],1)]}},{key:"ammonium_header",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"table-col-header-container"},[_c('el-tooltip',{attrs:{"popperClass":"lab-test-table-el-tooltip__popper","content":header.sub_label,"placement":"top","effect":"light"}},[_c('p',[_vm._v(_vm._s(header.label))])])],1)]}},{key:"tan_header",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"table-col-header-container"},[_c('el-tooltip',{attrs:{"popperClass":"lab-test-table-el-tooltip__popper","content":header.sub_label,"placement":"top","effect":"light"}},[_c('p',[_vm._v(_vm._s(header.label))])])],1)]}},{key:"iron_header",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"table-col-header-container"},[_c('el-tooltip',{attrs:{"popperClass":"lab-test-table-el-tooltip__popper","content":header.sub_label,"placement":"top","effect":"light"}},[_c('p',[_vm._v(_vm._s(header.label))])])],1)]}},{key:"magnesium_header",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"table-col-header-container"},[_c('el-tooltip',{attrs:{"popperClass":"lab-test-table-el-tooltip__popper","content":header.sub_label,"placement":"top","effect":"light"}},[_c('p',[_vm._v(_vm._s(header.label))])])],1)]}},{key:"calcium_header",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"table-col-header-container"},[_c('el-tooltip',{attrs:{"popperClass":"lab-test-table-el-tooltip__popper","content":header.sub_label,"placement":"top","effect":"light"}},[_c('p',[_vm._v(_vm._s(header.label))])])],1)]}},{key:"potassium_header",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"table-col-header-container"},[_c('el-tooltip',{attrs:{"popperClass":"lab-test-table-el-tooltip__popper","content":header.sub_label,"placement":"top","effect":"light"}},[_c('p',[_vm._v(_vm._s(header.label))])])],1)]}},{key:"ntu_header",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"table-col-header-container"},[_c('el-tooltip',{attrs:{"popperClass":"lab-test-table-el-tooltip__popper","content":header.sub_label,"placement":"top","effect":"light"}},[_c('p',[_vm._v(_vm._s(header.label))])])],1)]}},{key:"pond_id",fn:function(ref){
var row = ref.row;
return [_c('er-select',{attrs:{"size":"mini","placeholder":_vm.$t('Ponds_select_pond_name')},on:{"change":function($event){return _vm.handleChangeInPond($event, row.$index)}},model:{value:(row.data.pond_id),callback:function ($$v) {_vm.$set(row.data, "pond_id", $$v)},expression:"row.data.pond_id"}},_vm._l((_vm.getPondsInCulture),function(pond){return _c('el-option',{key:pond._id,attrs:{"disabled":_vm.getIsOptionAvailable(pond._id),"value":pond._id,"label":pond.title}})}),1)]}},{key:"lab_id",fn:function(ref){
var row = ref.row;
return [_c('er-select',{attrs:{"size":"mini","placeholder":_vm.$t('Resource_lab_name')},model:{value:(row.data.lab_id),callback:function ($$v) {_vm.$set(row.data, "lab_id", $$v)},expression:"row.data.lab_id"}},_vm._l((_vm.getSortedLabs),function(ref){
var _id = ref._id;
var title = ref.title;
return _c('el-option',{key:_id,attrs:{"value":_id,"label":title}})}),1)]}},{key:"ph",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"controls":false,"size":"mini","min":0},model:{value:(row.data.ph),callback:function ($$v) {_vm.$set(row.data, "ph", $$v)},expression:"row.data.ph"}})]}},{key:"salinity",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"controls":false,"size":"mini","min":0},model:{value:(row.data.salinity),callback:function ($$v) {_vm.$set(row.data, "salinity", $$v)},expression:"row.data.salinity"}})]}},{key:"do",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"controls":false,"size":"mini","min":0},model:{value:(row.data.do),callback:function ($$v) {_vm.$set(row.data, "do", $$v)},expression:"row.data.do"}})]}},{key:"temperature",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"controls":false,"size":"mini","min":0},model:{value:(row.data.temperature),callback:function ($$v) {_vm.$set(row.data, "temperature", $$v)},expression:"row.data.temperature"}})]}},{key:"co3",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"controls":false,"size":"mini","min":0},on:{"change":function($event){return _vm.onCo3Change(row.data)}},model:{value:(row.data.co3),callback:function ($$v) {_vm.$set(row.data, "co3", $$v)},expression:"row.data.co3"}})]}},{key:"hco3",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"controls":false,"size":"mini","min":0},on:{"change":function($event){return _vm.onHco3Change(row.data)}},model:{value:(row.data.hco3),callback:function ($$v) {_vm.$set(row.data, "hco3", $$v)},expression:"row.data.hco3"}})]}},{key:"alkallnity_total",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"controls":false,"size":"mini","disabled":false},model:{value:(row.data.alkallnity_total),callback:function ($$v) {_vm.$set(row.data, "alkallnity_total", $$v)},expression:"row.data.alkallnity_total"}})]}},{key:"hardness",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"controls":false,"size":"mini","min":0},model:{value:(row.data.hardness),callback:function ($$v) {_vm.$set(row.data, "hardness", $$v)},expression:"row.data.hardness"}})]}},{key:"ammonia",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"controls":false,"size":"mini","min":0},on:{"change":function($event){return _vm.onNH3Change(row.data)}},model:{value:(row.data.ammonia),callback:function ($$v) {_vm.$set(row.data, "ammonia", $$v)},expression:"row.data.ammonia"}})]}},{key:"ammonium",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"controls":false,"size":"mini","min":0},on:{"change":function($event){return _vm.onNH4Change(row.data)}},model:{value:(row.data.ammonium),callback:function ($$v) {_vm.$set(row.data, "ammonium", $$v)},expression:"row.data.ammonium"}})]}},{key:"tan",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"controls":false,"size":"mini","min":0},model:{value:(row.data.tan),callback:function ($$v) {_vm.$set(row.data, "tan", $$v)},expression:"row.data.tan"}})]}},{key:"iron",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"controls":false,"size":"mini","min":0},model:{value:(row.data.iron),callback:function ($$v) {_vm.$set(row.data, "iron", $$v)},expression:"row.data.iron"}})]}},{key:"green_colonies",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"controls":false,"size":"mini","min":0},model:{value:(row.data.green_colonies),callback:function ($$v) {_vm.$set(row.data, "green_colonies", $$v)},expression:"row.data.green_colonies"}})]}},{key:"yellow_colonies",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"controls":false,"size":"mini","min":0},model:{value:(row.data.yellow_colonies),callback:function ($$v) {_vm.$set(row.data, "yellow_colonies", $$v)},expression:"row.data.yellow_colonies"}})]}},{key:"no2",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"controls":false,"size":"mini"},model:{value:(row.data.no2),callback:function ($$v) {_vm.$set(row.data, "no2", $$v)},expression:"row.data.no2"}})]}},{key:"calcium",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"controls":false,"size":"mini","min":0},model:{value:(row.data.calcium),callback:function ($$v) {_vm.$set(row.data, "calcium", $$v)},expression:"row.data.calcium"}})]}},{key:"magnesium",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"controls":false,"size":"mini","min":0},model:{value:(row.data.magnesium),callback:function ($$v) {_vm.$set(row.data, "magnesium", $$v)},expression:"row.data.magnesium"}})]}},{key:"potassium",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"controls":false,"size":"mini","min":0},model:{value:(row.data.potassium),callback:function ($$v) {_vm.$set(row.data, "potassium", $$v)},expression:"row.data.potassium"}})]}},{key:"ntu",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"controls":false,"size":"mini","min":0},model:{value:(row.data.ntu),callback:function ($$v) {_vm.$set(row.data, "ntu", $$v)},expression:"row.data.ntu"}})]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [(row.$index === _vm.tableDataLength - 1)?[_c('er-button',{attrs:{"btnType":"add","type":"text","size":"mini","showLabel":true,"showIcon":true,"disabled":_vm.isReadOnly},on:{"click":function($event){return _vm.handleAddRow()}}})]:[_c('er-button',{attrs:{"btnType":"delete","type":"text","showLabel":true,"showIcon":true,"size":"mini","disabled":_vm.isReadOnly},on:{"click":function($event){return _vm.handleDeleteRow(row.$index)}}})]]}}],null,false,2880597593)}),_vm._v(" "),_c('el-row',{staticClass:"table-footer"},[_c('er-button',{attrs:{"btnType":"save","showLabel":true,"showIcon":true,"loading":_vm.btnloading,"size":"mini","disabled":_vm.isReadOnly},on:{"click":_vm.handleSaveTableData}}),_vm._v(" "),_c('er-button',{attrs:{"btnType":"cancel","showLabel":true,"size":"mini","disabled":_vm.isReadOnly},on:{"click":_vm.handleCancel}})],1)],1):(_vm.loading)?_c('Loader'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }