/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: labtest.js
Description: This file has the model,functions  of lab test object used in the pondlogs customer site
*/
export default class LabTest {
  constructor() {
    this.pond_id = undefined;
    this.lab_id = undefined;
    this.ph = undefined;
    this.salinity = undefined;
    this.do = undefined;
    this.temperature = undefined;
    this.co3 = undefined;
    this.hco3 = undefined;
    this.alkallnity_total = undefined;
    this.hardness = undefined;
    this.ammonia = undefined;
    this.ammonium = undefined;
    this.tan = undefined;
    this.iron = undefined;
    this.green_colonies = undefined;
    this.yellow_colonies = undefined;
    this.no2 = undefined;
    this.calcium = undefined;
    this.magnesium = undefined;
    this.potassium = undefined;
    this.ntu = undefined;
  }
}
